//colors
// Nexolink
/*
$primary-color: #2775a8;
$dark-primary-color: #1c4f72;
$secondary-color: #2AC9DB;
$dark-secondary-color: #1f94a1;
$disable-color: #727f88;
$active-color: #8bc34a;
$dark-active-color: #679237;
$detail-color:#d3f8d5;
$background-color: #2775a8;
$secondary-background-color: #2AC9DB;
$filter-header:#2775a8bf;
$background-body:rgb(255,255,255);// rgba(168,180,187,1);
$secondary-background-body:rgba(255,255,255,1);
$table-detail-color:#d3f8d5;
$nav-bar-font-color:#ffffff;
$nav-bar-background-color:rgba(168,180,187,0.05);
$brandbar-background-color:#2775a8;

$green-color: #34c521;
$yellow-color: #e2e61f;
$red-color: #e61f1f;
$dark-grey: #333447;

$button-font-size: 16px;
$button-icon-size: 18px;
*/
/* Premier*/
// $primary-color: #434547;
// $dark-primary-color: #acb8c1;
// $secondary-color: #fcb715;
// $dark-secondary-color: #aaaaaa;
// $disable-color: #727f88;
// $active-color: #fcb715;
// $dark-active-color: #434547;
// $detail-color:#434547;
// $background-color: #f8fafb;
// $secondary-background-color: #ffffff;
// $filter-header:#aaaaaa;
// $background-body:#f8fafb;
// $secondary-background-body:#ffffff;
// $table-detail-color:#f8fafb;
// $nav-bar-font-color:#434547;
//$nav-bar-background-color:rgba(168,180,187,0.05);
//$brandbar-background-color:#f8fafb;

// $green-color: #34c521;
// $yellow-color: #e2e61f;
// $red-color: #e61f1f;
// $dark-grey: #333447;
// $dark-gray: #333447;

//$button-font-size: 16px;
//$button-icon-size: 18px;

/* FIRST COLORS */
/*
$primary-color: #2775a8;
$dark-primary-color: #1c4f72;
$secondary-color: #2AC9DB;
$dark-secondary-color: #1f94a1;
$disable-color: #727f88;
*/

/* Xushi*/
/*
 $primary-color: #c2922c;
 $dark-primary-color: #434547;
 $secondary-color: #c2922c;
 $dark-secondary-color: #b2b9be;
 $disable-color: #727f88;
 $active-color: #c2922c;
 $dark-active-color: #434547;
 $detail-color:#434547;
 $background-color: #ffffff;
 $secondary-background-color: #ffffff;
 $filter-header:#aaaaaa;
 $background-body:#f8fafb;
 $secondary-background-body:#ffffff;
 $table-detail-color:#f8fafb;
 $nav-bar-font-color:#434547;
 $nav-bar-background-color:rgba(168,180,187,0.05);
 $brandbar-background-color:rgba(168,180,187,0.05);

 $green-color: #34c521;
 $yellow-color: #e2e61f;
 $red-color: #e61f1f;
 $dark-grey: #333447;
 $dark-gray: #333447; 

$button-font-size: 16px;
$button-icon-size: 18px;*/

// QFX
/*
$primary-color: #3782B8;
$dark-primary-color: #1c4f72;
$secondary-color: #4EE4B7;
$dark-secondary-color: #387f6a;
$disable-color: #727f88;
$active-color: #8bc34a;
$dark-active-color: #679237;
$detail-color:#d3f8d5;
$background-color: #3782B8;
$secondary-background-color: #4EE4B7;
$filter-header:#3782B8bf;
$background-body:rgba(168,180,187,1);
$secondary-background-body:rgba(255,255,255,1);
$table-detail-color:#d3f8d5;
$nav-bar-font-color:#ffffff;
$nav-bar-background-color:rgba(168,180,187,0.05);
$brandbar-background-color:#3782B8;

$green-color: #34c521;
$yellow-color: #e2e61f;
$red-color: #e61f1f;
$dark-grey: #333447;

$button-font-size: 16px;
$button-icon-size: 18px;*/


// LFX - Lighthouse

$primary-color: #E28824;
$dark-primary-color: #E24C24;
$secondary-color: #c1cacb;
$dark-secondary-color: #84898a;
$disable-color: #727f88;
$active-color: #E28824;
$dark-active-color: #845015;
$detail-color:#333447;//#d3f8d5;
$background-color: #FFFFFF;
$secondary-background-color: #FFFFFF;
$filter-header:#E28824bf;
$background-body:rgb(255,255,255);
$secondary-background-body:rgba(255,255,255,1);
$table-detail-color:#d3f8d5;
$nav-bar-font-color:#ffffff;
$nav-bar-background-color:rgba(168,180,187,0.05);
$brandbar-background-color:#E28824;

$green-color: #34c521;
$yellow-color: #e2e61f;
$red-color: #e61f1f;
$dark-grey: #333447;

$button-font-size: 16px;
$button-icon-size: 18px;

